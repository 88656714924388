.aboutusaim {
  section.content {
    &#aboutusaim-first-content {
      .left {
        p {
          padding: 0;
          margin: 0;
          font-size: 32px;
          font-weight: bold;
          text-align: center;
          @media (max-width: 1198px) {
            font-size: 30px;
          }
          @media (max-width: 600px) {
            font-size: 20px;
          }
          @media (max-width: 350px) {
            font-size: 18px;
          }
        }
      }
    }
  }
}
