.ui.sponser {
  padding: 25px 0px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  img.ui {
    height: 106px;
    &:nth-child(1) {
      width: 301px;

      margin: auto 10px;
      padding: 10px;
    }
    &:nth-child(2) {
      margin: auto 10px;
      width: 232px;
      padding: 10px;
    }
    &:nth-child(3) {
      margin: auto 10px;
      width: 332px;

      padding: 10px;
    }
  }
}
