#root {
  // height: 100%;
  display: flex;
  min-height: 100%;
  overflow: auto;
  flex-direction: column;
  .mobile {
    height: 100%;
    flex: 1 0 0;
    .pushable {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
    }
    .pusher {
      overflow: auto;
      flex: 1 0 0;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
  .desktop {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 0 0;
  }
  .menu-header {
    height: 90px;
    > .item {
      @media (max-width: 320px) {
        width: 240px;
      }
    }
    .right.item {
      margin: auto 0;
      img {
        cursor: pointer;
        margin-left: 5px;
      }
    }
  }
  .inverted.menu {
    border: 1px solid #222;
    background-color: #111;
    background-image: linear-gradient(#444, #111);
    border-radius: 0;
    box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;

    .item {
      padding: 12px 30px;
      color: #999;
      @media (max-width: 990px) {
        padding: 12px 20px;
      }
      &:hover {
        color: white;
      }
      &:active {
        color: white;
      }
    }
  }
  .body {
    background-color: rgba(0, 0, 0, 0.9);
    flex: 1 0 0;
    & > div {
      margin: 10px;
      section.content {
        display: flex;
        @media (max-width: 990px) {
          display: block;
        }
        .left {
          max-width: 670px;
          width: 100%;
          .images {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            img {
              //max-width: 200px;
              margin: 20px 8px;
              width: 45%;
              max-width: 235px;
              @media (max-width: 990px) {
                max-width: 335px;
              }
              @media (max-width: 458px) {
                width: 40%;
              }
            }
          }
        }
        .right {
          margin-top: 20px;
          margin-left: 20px;
          width: 100%;
          max-width: 543.5px;
          @media (max-width: 990px) {
            margin-left: auto;
            margin-right: auto;
          }
          .images {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            img {
              //max-width: 200px;
              margin: 20px 8px;
              width: 45%;
              max-width: 235px;
              height: 100%;
              @media (max-width: 990px) {
                max-width: 335px;
              }
              @media (max-width: 458px) {
                width: 40%;
              }
            }
          }
        }
      }
    }
  }
}

h2 {
  color: #ff3808;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
p {
  font-size: 16px;
  text-align: left;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;

  font-style: normal;
  font-variant: normal;
  font-weight: normal;
}

ul {
  li {
    color: #ffffff;
    font-size: 14px;
    text-align: left;
  }
}

a.download {
  font-size: 24px;
  cursor: pointer;
  text-decoration: underline;
  color: #ff3c00;
  line-height: 24px;
  display: flex;
  img {
    width: 24px;
    margin-right: 5px;
  }
}

.awssld {
  --slider-height-percentage: 40%;
  --slider-transition-duration: 770ms;
  --organic-arrow-thickness: 6px;
  --organic-arrow-border-radius: 30px;
  --organic-arrow-height: 40px;
  --organic-arrow-color: #000000;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-background: transparent;
  --control-bullet-color: #c0c0c0;
  --control-bullet-active-color: #ff0000;
  --loader-bar-color: #851515;
  --loader-bar-height: 6px;
}
