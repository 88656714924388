.menber {
  section.content {
    &#menber-first-content {
      flex-direction: column;
      .content {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 70%;
        margin: 0 auto;
        .left {
          min-width: 35%;

          @media (min-width: 991px) {
            width: auto;
          }
          ul {
            list-style: none;
          }
          max-width: 100% !important;
        }
        .right {
          min-width: 35%;
          @media (min-width: 991px) {
            width: auto;
          }
          max-width: 100% !important;
          margin: 0;
        }
      }
      .bottom {
        text-align: center;
        margin: auto;
        img {
          width: 260px;
        }
        a {
          display: block;
        }
      }
    }
    &#menber-images-content {
      justify-content: center;
      padding-top: 15px;
      .images {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-self: flex-start;
        img {
          //max-width: 200px;
          margin: 20px 8px;
          width: 100%;
          height: 100%;
          max-width: 235px;
          @media (max-width: 990px) {
            max-width: 335px;
            width: 45%;
          }
          @media (max-width: 458px) {
            width: 40%;
          }
        }
      }
    }
  }
}
