.sportfac {
  section.content {
    &#sportfac-first-content {
      flex-direction: column;
    }
    &#sportfac-image-content {
      padding-top: 10px;
      .left {
        max-width: 454px !important;
        margin-right: 15px;
        margin-bottom: 15px;
        @media (max-width: 990px) {
          margin: 10px auto;
        }
        .images {
          padding: 15px 0;
          img {
            margin: 0 !important;
            min-height: 153px;
            width: 100% !important;
            height: 100%;
          }
          p {
            margin: auto;
          }
        }
        :nth-child(1) {
          img {
          }
          p {
            margin-left: 10px;
          }
        }
        :nth-child(2) {
          p {
            margin-right: 10px;
          }
        }

        :nth-child(3) {
          p {
            margin-left: 10px;
          }
        }
      }
      .right {
        margin: 0 auto !important;
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            color: black;
          }
        }
        table,
        th,
        td {
          color: black;
          border: 1px solid white;
          border-collapse: collapse;
        }
        table {
          width: 100%;
          font-size: 16px;
          thead {
            tr {
              th {
                background: rgb(112, 173, 71);
                font-weight: bold;
                padding: 10px;
              }
            }
          }
          tbody {
            tr {
              background: rgb(212, 226, 206);
              td {
                &:nth-child(1) {
                  background: rgb(112, 173, 71);
                  font-weight: bold;
                }
                padding: 10px;
              }
            }
            tr:nth-child(even) {
              background-color: #f2f2f2;
            }
          }
        }
        .pdf-download {
          padding: 45px 0;
          font-size: 24px;
          color: white;
          display: flex;
          justify-content: space-around;
          img {
            width: 24px;
            margin-right: 5px;
          }
          a {
            color: white;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
