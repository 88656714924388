.events {
  section.content {
    &#events-first-content {
      flex-direction: column;
    }
    &#events-sec-content {
      padding-top: 15px;
      > div {
        text-align: center;
      }
    }
    &#events-thr-content {
      padding-top: 5px;
    }
  }
  .row {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    padding: 0 4px;
    /* Create four equal columns that sits next to each other */
    .column {
      -ms-flex: 33.333%; /* IE10 */
      flex: 33.333%;
      max-width: 33.333%;
      padding: 0 4px;

      .image {
        margin-top: 8px;
        vertical-align: middle;
        width: 100%;
        position: relative;
        .title {
          font-size: 20px;
          font-weight: bold;
          position: absolute;
          width: 100%;
          bottom: 0;
          min-height: 35px;
          background: rgba(0, 0, 0, 0.4);
          color: white;
          text-align: right;
          line-height: 24px;
          margin: auto;
          padding: 0 10px;
        }
        img {
          width: 100%;
        }
      }
      /* Responsive layout - makes a two column-layout instead of four columns */
      @media screen and (max-width: 800px) {
        -ms-flex: 33.333%; /* IE10 */
        flex: 33.333%;
        max-width: 33.333%;
      }

      /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
      @media screen and (max-width: 600px) {
        -ms-flex: 100%;
        flex: 100%;
        max-width: 100%;
      }
    }
  }
}
