.aboutus {
  section.content {
    padding-bottom: 25px;
    &#aboutus-first-content {
      img#logo {
        width: 160px;
      }
      .left {
        .header {
          color: #ff3808;
          font-size: 24px;
        }
        .list {
          .item {
            font-size: 16px;
            color: white;
          }
        }
      }
      .right {
        margin: auto !important;
        .social-media {
          margin: auto 0;
          img {
            cursor: pointer;
          }
        }
      }
    }
    &#aboutus-google-map {
      min-height: 500px;
      // justify-content: center;
      a {
        display: block;
        img {
          width: 100%;
        }
      }
    }
  }
}
