.home {
  height: inherit;
  section.content {
    &#home-slider {
      height: 100%;
      padding-bottom: 45px;
      & > div {
        width: 100%;
      }
    }
    &#home-first-content {
      flex-direction: column;
    }
    &#home-site-map {
      > div {
        width: 100%;
        padding: 20px 0;
        h4.header {
          font-size: 24px;
          color: #ff3808;
          font-weight: bold;
        }
        .site-link-list {
          > a {
            width: fit-content;
          }
        }
      }
    }
  }
}
